import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ConfirmationComponent } from '@core/actions-modals/confirmation.component';
import { TranslateUiModule } from '@core/translation/translate-ui.module';
import { BtnLoaderModule } from '@ui-kit/btn-loader/btn-loader.module';

@NgModule({
  declarations: [ConfirmationComponent],
  exports: [ConfirmationComponent],
  imports: [CommonModule, TranslateUiModule, BtnLoaderModule, MatTooltipModule]
})
export class ActionsModalsModule {}
