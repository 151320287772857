import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { InjectionToken } from '@angular/core';
import { map } from 'rxjs/operators';

export const isMobile = new InjectionToken('isMobile');

export function isMobileFactory(breakPointObserver: BreakpointObserver) {
  return breakPointObserver.observe(['(max-width: 768px)']).pipe(map((state: BreakpointState) => state.matches));
}

export const apiUrl = new InjectionToken('apiUrl');

export function apiUrlFactory(url: string) {
  return url;
}
