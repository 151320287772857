import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Udf } from '@core/Udf';
import { AuthService } from '@features/auth/auth.service';
import { StorageService } from '@services/common/storage.service';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor, OnDestroy {
  private destroyed$ = new Subject();
  private token: Udf<string>;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.token = this._authService.getAccessToken();
    const token = this.token;

    if (token) {
      let headers = {
        Authorization: `Bearer ${token}`
      };

      const deviceId = this._localStorage.getItem('deviceId');
      const platform = this._localStorage.getItem('platform');

      if (deviceId && platform) {
        const mobileSchedulerKeys = {
          'X-App-Platform': platform,
          'X-App-Device-ID': deviceId
        };

        headers = { ...headers, ...mobileSchedulerKeys };
      }

      return next
        .handle(
          req.clone({
            setHeaders: headers
          })
        )
        .pipe(
          tap(
            () => {},
            (err: any) => {
              if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                  if (!deviceId && !platform) {
                    this._authService.logout();
                  }
                }
              }
            }
          )
        );
    }

    return next.handle(req);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  constructor(
    private _localStorage: StorageService,
    private _authService: AuthService
  ) {}
}
