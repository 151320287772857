import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Toast } from '@ui-kit/app-toast/toast.interface';
import { ToasterService } from '@ui-kit/app-toast/toaster.service';

@Component({
  selector: 'app-toast-container',
  templateUrl: './app-toast-container.component.html',
  styleUrls: ['./app-toast-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppToastContainerComponent implements OnInit {
  _toasts: Toast[] = [];
  toasts$: BehaviorSubject<Toast[]> = new BehaviorSubject<Toast[]>(this._toasts);

  constructor(private _toaster: ToasterService) {}

  ngOnInit(): void {
    this._toaster.toast$.subscribe((toast) => {
      this._toasts.push(toast);
      this.toasts$.next(this._toasts);
    });
  }

  remove(index: number): void {
    this._toasts = this._toasts.filter((v, i) => i !== index);
    this.toasts$.next(this._toasts);
  }
}
