import { APP_INITIALIZER, EnvironmentProviders, inject, makeEnvironmentProviders } from '@angular/core';
import { FeatureFlagService } from '@core/feature-flag/feature-flag.service';
import { AuthService } from '@features/auth/auth.service';

export function initializeFeatureFlags() {
  const featureFlagsFacade = inject(FeatureFlagService);
  const auth = inject(AuthService);
  const user = auth.user;

  return (): Promise<any> => {
    if (!user && !user?.account?.id && !user?.account?.contact_person_email) {
      return new Promise(async (resolve, reject) => {
        resolve(true);
      });
    }

    const account = user?.account;

    const client = featureFlagsFacade.initialize(user, {
      id: user?.account?.id,
      email: user?.account?.contact_person_email,
      ...account
    });

    return new Promise(async (resolve, reject) => {
      try {
        await client.waitUntilReady();
        featureFlagsFacade.onReadyFlagsClient();
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export function provideFeatureFlags(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: APP_INITIALIZER,
      useFactory: () => initializeFeatureFlags(),
      deps: [FeatureFlagService, AuthService],
      multi: true
    }
  ]);
}
