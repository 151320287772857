import { APP_INITIALIZER, EnvironmentProviders, inject, makeEnvironmentProviders } from '@angular/core';
import { AuthService } from '@features/auth/auth.service';

import { TranslateService } from '@ngx-translate/core';
import { LanguagesList } from '@features/auth/languages';

export function initializeTranslationFactory() {
  const translate = inject(TranslateService);
  const auth = inject(AuthService);
  const authUser = auth.currentUser;

  let lang = null;

  if (authUser) {
    lang = LanguagesList.get(authUser.language_id);
  } else {
    if (!translate.currentLang) {
      const browserLanguage = translate.getBrowserLang();
      const supportedLanguages = ['da', 'de', 'en', 'lt', 'lv', 'nb', 'pl', 'ru', 'sr'];
      const language = supportedLanguages.includes(browserLanguage) ? browserLanguage : 'nb';
      lang = language;
    }
  }

  if (authUser && !auth.profileItem.loaded && !auth.profileItem.loading) {
    if (authUser.role !== 'super_admin' && authUser.role !== 'staff') {
      auth.initProfile();
    }
  }

  return () => {
    translate.setDefaultLang(lang);
    return translate.use(lang).toPromise();
  };
}

export function provideTranslations(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: APP_INITIALIZER,
      useFactory: () => initializeTranslationFactory(),
      deps: [TranslateService, AuthService],
      multi: true
    }
  ]);
}
