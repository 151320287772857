import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

setTimeout(() => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule, {
      ngZone: 'noop'
    })
    .catch((err) => console.log(err));
});

window.addEventListener('load', () => {
  const handleNetworkChange = function (onLine: boolean): void {
    if (onLine) {
      document.body.classList.remove('offline');
    } else {
      document.body.classList.add('offline');
    }
  };

  handleNetworkChange(navigator.onLine);

  window.addEventListener('online', function () {
    handleNetworkChange(true);
  });

  window.addEventListener('offline', function () {
    handleNetworkChange(false);
  });
});
