import { AnimationEvent } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { toastAnimations, ToastAnimationState } from '@ui-kit/app-toast/app-toast/toast-animation';
import { DefaultToastConfig } from '@ui-kit/app-toast/app-toast/toast.config';
import { Toast } from '@ui-kit/app-toast/toast.interface';

import { setTimeout, clearTimeout } from '@rx-angular/cdk/zone-less/browser';

marker('success');
marker('error');

@Component({
  selector: 'app-toast',
  templateUrl: './app-toast.component.html',
  styleUrls: ['./app-toast.component.scss'],
  animations: [toastAnimations.fadeToast],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppToastComponent implements OnInit, OnDestroy {
  @Input() toast: Toast;
  @Input() i: number;
  @Output() remove = new EventEmitter<number>();

  iconUrl = '/assets/toast/toast-';
  animationState: ToastAnimationState = 'default';
  toastState = 'success';
  toastConfig = DefaultToastConfig;

  private intervalId: number | any;

  close() {
    this.remove.next(this.i);
  }

  onFadeFinished(event: AnimationEvent) {
    const { toState } = event;

    const isFadeOut = (toState as ToastAnimationState) === 'closing';
    const itFinished = this.animationState === 'closing';

    if (isFadeOut && itFinished) {
      this.close();
    }
  }

  ngOnInit(): void {
    this.iconUrl += `${this.toast.type}.svg`;
    this.toastState = this.toast.type;

    this.intervalId = setTimeout(() => {
      this.animationState = 'closing';
      this._cdr.detectChanges();
    }, 2500);
  }

  ngOnDestroy(): void {
    clearTimeout(this.intervalId);
  }

  constructor(
    private _cdr: ChangeDetectorRef,
    private _el: ElementRef
  ) {}
}
