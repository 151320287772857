import { Routes } from '@angular/router';

import { AuthGuard } from '@features/auth/auth.guard';
import { SilentLoginComponent } from '@shared/components/silent-login/silent-login.component';
import { CanActivateMainGuard } from '@shared/guards/loaded-integrations.guard';
import { SubscriptionPlanGuard } from '@shared/guards/subscription-plan.guard';

export const appRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/app/home/dashboard'
  },
  {
    path: 'auth',
    loadChildren: () => import('./features/auth/auth.module').then((m) => m.AuthModule),
    data: { breadcrumb: { skip: true } }
  },
  {
    path: 'app',
    loadChildren: () => import('./features/main/main.module').then((m) => m.MainModule),
    data: { breadcrumb: { skip: true } },
    canActivate: [AuthGuard, CanActivateMainGuard]
  },
  {
    path: 'trial-ended',
    loadChildren: () => import('./features/trial-ended/trial-ended.module').then((m) => m.TrialEndedModule),
    data: { breadcrumb: { skip: true } },
    canActivate: [AuthGuard, SubscriptionPlanGuard]
  },
  {
    path: 'no-auth',
    component: SilentLoginComponent
  },
  {
    path: 'calendar',
    loadChildren: () => import('./features/mobile-scheduler/mobile-scheduler.module').then((m) => m.MobileSchedulerModule)
  },
  {
    path: 'support-chat',
    loadChildren: () => import('./features/hubspot-chat-widget/hubspot-chat-widget.module').then((m) => m.HubspotChatWidgetModule)
  },
  {
    path: '**',
    redirectTo: '/app/home/dashboard'
  }
];
