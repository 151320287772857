import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@features/auth/auth.service';
import { StorageService } from '@services/common/storage.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'silent-login',
  templateUrl: './silent-login.component.html',
  styleUrls: ['./silent-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SilentLoginComponent {
  authUser(token, userId) {
    this._localStorage.setItem('token', token);
    this._localStorage.setItem('user', {
      id: userId
    });

    return this._auth.initProfile();
  }

  constructor(
    private _auth: AuthService,
    private _localStorage: StorageService,
    private _route: ActivatedRoute
  ) {
    const params = this._route.snapshot.queryParams;

    window.localStorage.clear();

    setTimeout(() => {
      const token = JSON.parse(params['token']);
      const userId = JSON.parse(params['userId']);
      const silentLogin = JSON.parse(params['silentLogin']);
      const loginType = params['type'];

      this.authUser(token, userId)
        .pipe(take(1))
        .subscribe((data) => {
          silentLogin && this._localStorage.setItem('silentLogin', silentLogin);

          if (loginType && !silentLogin) {
            this._auth.initSegmentOnLogin({ user: this._auth.profileItem.data }, loginType);
          }

          setTimeout(() => {
            window.location.replace('/');
          }, 1000);
        });
    }, 100);
  }
}
