<section class="confirm-email-alert">
  <div class="message">
    <span>{{ 'Please confirm your email address' | translate }}: {{ userEmail }}</span>
  </div>

  <div class="confirm-email-alert__actions">
    <span class="clickable" translate (click)="resendEmailConfirm()">Resend email</span>
    <span class="clickable" translate (click)="changeEmail()">Change email</span>
  </div>

  <button class="btn-close" (click)="closeEmailConfirmBanner()">
    <i class="icon icon-close_ico"></i>
  </button>
</section>
