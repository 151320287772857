import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateUiModule } from '@core/translation/translate-ui.module';
import { ForModule } from '@rx-angular/template/for';
import { SafePipeModule } from '@ui-kit/pipes/safe-pipe/safe-pipe.module';
import { AppToastContainerComponent } from './app-toast-container/app-toast-container.component';
import { AppToastComponent } from './app-toast/app-toast.component';

@NgModule({
  declarations: [AppToastContainerComponent, AppToastComponent],
  imports: [CommonModule, SafePipeModule, TranslateUiModule, ForModule],
  exports: [AppToastContainerComponent]
})
export class AppToastModule {}
