import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { environment } from '../environments/environment';
import { AuthService } from '@features/auth/auth.service';
import { MobileSchedulerService } from '@features/mobile-scheduler/mobile-scheduler.service';
import { CookieService } from '@services/common/cookie.service';
import { HubspotService } from '@services/common/hubspot.service';
import { ConfirmEmailBannerService } from '@shared/components/confirm-email-banner/confirm-email-banner.service';
import { Collection, Item } from 'collection';
import { CrudService } from 'collection';
import { BreadcrumbService } from 'xng-breadcrumb';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
Collection.prototype.mapResponse = (res) => {
  if (!Object.prototype.hasOwnProperty.call(res.data, 'items')) {
    return res.data;
  }

  return {
    items: res.data.items,
    meta: {
      currentPage: res.data?._meta?.currentPage,
      pageCount: res.data?._meta?.pageCount,
      perPage: res.data?._meta?.perPage,
      totalCount: res.data?._meta?.totalCount
    }
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
Item.prototype.mapResponse = (res) => {
  return res.data;
};

@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  showUpdateMode = false;
  isMobile = false;
  userEmail = '';
  showIEAlert = false;
  showConfirmEmail = false;
  isIE = /msie\s|trident\//i.test(window.navigator.userAgent);

  $isEmailConfirmOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $isValidRouteForConfirm: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _destroyed$ = new Subject();

  constructor(
    private _breadcrumbService: BreadcrumbService,
    private _auth: AuthService,
    private _crud: CrudService,
    private _confirmEmailBannerService: ConfirmEmailBannerService,
    private _mobileSchedulerService: MobileSchedulerService,
    private _renderer2: Renderer2,
    private _cookieService: CookieService,
    private _swUpdate: SwUpdate,
    private _cdr: ChangeDetectorRef,
    private _router: Router,
    private _hubspotService: HubspotService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this._hubspotService.defaultChatSettings();
    this.$isEmailConfirmOpened = this._confirmEmailBannerService.isEmailConfirmOpened;
    this.addSegmentScript();
    this.overrideCollectionMultiSelectCallbacks();
    this.initBreadcrumbs();
  }

  initRouteSubscription(): void {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('/app') || event.urlAfterRedirects.includes('/app')) {
          this.$isValidRouteForConfirm.next(true);
        }
      }
    });
  }

  updateCache(): void {
    window.location.reload();
  }

  closeChat(): void {
    const chatContainerParent = document.getElementById('hubspot-messages-iframe-container');
    const chatContainer = chatContainerParent.childNodes[0];
    // @ts-ignore
    if (chatContainer.classList.contains('active')) {
      this._hubspotService.hideWidget();
      this._hubspotService.closeWidget();
    } else {
      this._hubspotService.showWidget();
      this._hubspotService.openWidget();
    }
  }

  private initBreadcrumbs(): void {
    this._breadcrumbService['mergeWithBaseChildData'] = (routeConfig, config) => {
      let _a;
      if (!routeConfig) {
        return this._breadcrumbService['extractObject'](config);
      }
      let baseChild;
      if (routeConfig.loadChildren && routeConfig._loadedConfig) {
        // To handle a module with empty child route
        baseChild = routeConfig._loadedConfig.routes.find((route) => route.path === '');
      } else if (routeConfig.children) {
        // To handle a component with empty child route
        baseChild = routeConfig.children.find((route) => route.path === '');
      }
      const childConfig =
        (_a = baseChild === null || baseChild === void 0 ? void 0 : baseChild.data) === null || _a === void 0
          ? void 0
          : _a.breadcrumb;

      return childConfig
        ? this._breadcrumbService['mergeWithBaseChildData'](
            baseChild,
            Object.assign(
              Object.assign({}, this._breadcrumbService['extractObject'](config)),
              this._breadcrumbService['extractObject'](childConfig)
            )
          )
        : this._breadcrumbService['extractObject'](config);
    };

    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        take(1)
      )
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this._breadcrumbService['detectRouteChanges']();
        }
      });
  }

  private initIEAlert(): void {
    const showAlert = this._cookieService.get('show_ie_alert');

    if (this.isIE && showAlert) {
      this.showIEAlert = true;
    }
  }

  closeIEAlert(): void {
    this._cookieService.set('show_ie_alert', 'false', {
      path: '/'
    });
    this.showIEAlert = false;
  }

  goToLearnMore(url: string): void {
    window.open(url, '_blank');
  }

  addSegmentScript(): void {
    const segmentKey = environment.SEGMENT_TOKEN;
    if (segmentKey) {
      const script = this._renderer2.createElement('script');
      script.text = `
        !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="tBrhG3TCh2ePSdJeMZ0AyR3BEq9v6ld1";analytics.SNIPPET_VERSION="4.13.2";
        analytics.load("${segmentKey}");
        }}();
        `;

      this._renderer2.appendChild(this._document.body, script);
    }
  }

  // Main permission logic for list multiselect possibility
  isUserCanSelect(item): boolean {
    const isOwner: boolean = Number(item.user_id) === Number(this._auth.currentUser.id);
    return this._auth.isAdmin() || isOwner;
  }

  overrideCollectionMultiSelectCallbacks(): void {
    const mokCollection = new Collection();

    Collection.prototype.mapOnSelectItems = (item) => {
      item[mokCollection.canSelectItemKey] = this.isUserCanSelect(item);
      return item;
    };

    Collection.prototype.isOwnerCondition = (user_id) => {
      return this.isUserCanSelect({ user_id });
    };

    Collection.prototype.crudService = this._crud;
    Item.prototype.crudService = this._crud;
  }

  private updateNewVersionSubscription(): void {
    if (this._swUpdate.isEnabled) {
      const updatesAvailable = this._swUpdate.versionUpdates.pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map((evt) => ({
          type: 'UPDATE_AVAILABLE',
          current: evt.currentVersion,
          available: evt.latestVersion
        }))
      );

      updatesAvailable.subscribe((value) => {
        this.showUpdateMode = true;
        this._cdr.detectChanges();
      });
    }
  }

  ngOnInit(): void {
    this.initRouteSubscription();
    this._mobileSchedulerService.setIsMobile(false);
    this._mobileSchedulerService.$isMobile.subscribe((data) => {
      this.isMobile = data;
      if (data) {
        this.closeIEAlert();
      }
    });

    this.initIEAlert();
    this.updateNewVersionSubscription();
  }
}
