import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@features/auth/auth.service';
import { MarketplaceService } from '@features/marketplace/marketplace.service';
import { TrialEndedService } from '@features/trial-ended/trial-ended.service';
import { SidebarService } from '@services/common/sidebar.service';
import { StorageService } from '@services/common/storage.service';
import { forkJoin } from 'rxjs';
import { mapTo, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CanActivateMainGuard {
  constructor(
    private sidebarService: SidebarService,
    protected router: Router,
    private _auth: AuthService,
    private trialEndedService: TrialEndedService,
    private _localStorage: StorageService,
    private _marketplaceService: MarketplaceService
  ) {}

  canActivate() {
    const requests = this._auth.isAdmin()
      ? [this.sidebarService.initAvailableIntegrations(), this.trialEndedService.initSubscriptionDetails()]
      : [this.sidebarService.initAvailableIntegrations()];

    return forkJoin(requests).pipe(take(1), mapTo(true));
  }
}
