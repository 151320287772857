import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthService } from '@features/auth/auth.service';
import { ConfirmEmailBannerService } from '@shared/components/confirm-email-banner/confirm-email-banner.service';
import { PostEntityModel } from 'collection';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'confirm-email-banner',
  templateUrl: './confirm-email-banner.component.html',
  styleUrls: ['./confirm-email-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmEmailBannerComponent implements OnInit {
  resendEmailConfirmation: PostEntityModel;

  $isEmailConfirmOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  userEmail: string = '';

  private _destroyed$ = new Subject();

  resendEmailConfirm(): void {
    this._confirmEmailBannerService.resendEmailConfirm();
  }

  changeEmail(): void {
    this._confirmEmailBannerService.changeEmail();
  }

  closeEmailConfirmBanner(): void {
    this._confirmEmailBannerService.closeEmailConfirmBanner();
  }

  getUserEmail() {
    this._confirmEmailBannerService
      .getProfileData()
      .pipe(takeUntil(this._destroyed$))
      .subscribe((myProfileData) => {
        if (myProfileData) {
          this.userEmail = myProfileData?.email;
          this._cdr.markForCheck();
        }
      });
  }

  ngOnInit(): void {
    this.getUserEmail();
  }

  constructor(
    private _auth: AuthService,
    private _cdr: ChangeDetectorRef,
    private _confirmEmailBannerService: ConfirmEmailBannerService
  ) {
    this.$isEmailConfirmOpened = this._confirmEmailBannerService.isEmailConfirmOpened;
  }
}
