import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateUiModule } from '@core/translation/translate-ui.module';
import { ConfirmEmailBannerComponent } from './confirm-email-banner.component';

@NgModule({
  declarations: [ConfirmEmailBannerComponent],
  exports: [ConfirmEmailBannerComponent],
  imports: [CommonModule, TranslateUiModule]
})
export class ConfirmEmailBannerModule {}
